
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseIcon from '../../base/BaseIcon.vue'
import BaseLink from '../../base/BaseLink.vue'
import ILocationTeaser from '../../../shared/general/interfaces/ILocationTeaser'
import { trackCta } from '../../../shared/general/tracking/TrackingService'

@Component({
  name: 'TeaserLocationPage',
  components: {
    BaseIcon,
    BaseLink,
  },
})
export default class TeaserLocationPage extends Vue {
  @Prop({ required: true }) teaser! : ILocationTeaser

  @Prop() contentColor ?: string

  private elemIsHovered : boolean = false

  private track () {
    trackCta(this.$el as HTMLElement, {
      link_text: this.teaser.title,
      link_url: this.teaser.link?.url || '',
      icon_name: this.teaser.icon,
      cta_type: 'cta_location_stage',
    })
  }
}
